import React, {Component} from "react";

import "./RadioTabButtons.scss";
import "../../../InputField/InputField.scss";
import RadioButton from "../../../RadioButton/RadioButton";

import InputField from "../../../InputField/InputField";

interface TabButtonsProps {
  label: string;
  statePhone: string;
  phoneLoginErrorHelper: string;
  emailLoginErrorHelper: string;
  currentPhone: string;
  handleChangePhone: (e: any) => void;
  stateEmail: string;
  currentEmail: string;
  handleChangeEmail: (e: any) => void;
  handleFocusInput?: () => void;
  clearEmail: () => void;
  clearPhone: () => void;
}

interface State {
  active: string;
}

class RadioTabButtons extends Component<TabButtonsProps> {
  state: State = {
    active: "cellPhone",
  };

  resetCellPhone() {
    this.props.clearPhone();
  }

  resetEmail() {
    this.props.clearEmail();
  }

  render() {
    const { active } = this.state;
    const { label } = this.props;

    return (
      <div className="rtb-wrapper">
        <div className="rtb-label">{label}</div>

        <div className="rtb-item">
          <div className="rtb-item__cell-phone">
            <div>
              <RadioButton
                testId="rtb-item__cell-phone"
                checked={active === "cellPhone"}
                handleClick={() => { this.setState({ active: "cellPhone" }); this.resetEmail(); }}
                label="Celular"
              />
            </div>
          </div>
          <div className="rtb-item__email">
            <div>
              <RadioButton
                testId="rtb-item__email"
                checked={active === "email"}
                handleClick={() => { this.setState({ active: "email" }); this.resetCellPhone(); }}
                label="Correo electrónico"
              />
            </div>
          </div>
        </div>
        <div className={`rtb-input--${active === "email" && "hidden"}`}>
          <InputField
            id="inputCellPhone"
            testId="inputCellPhoneTestId"
            label=""
            placeholder="Ingresa tu número celular"
            errorHelper={this.props.phoneLoginErrorHelper}
            normalHelper=""
            classWrap="-login"
            tabState={true}
            state={this.props.statePhone}
            xs="12"
            lg="12"
            disabled={false}
            currentValue={this.props.currentPhone}
            handleChange={this.props.handleChangePhone}
            handleFocus={this.props.handleFocusInput}
            disablePaste={true}
          />
        </div>
        <div className={`rtb-input--${active === "cellPhone" && "hidden"}`}>
          <InputField
            id="inputEmail"
            testId="inputEmailTestId"
            label=""
            placeholder="Ingresa tu correo electrónico"
            errorHelper={this.props.emailLoginErrorHelper}
            normalHelper=""
            classWrap="-login"
            tabState={true}
            state={this.props.stateEmail}
            xs="12"
            lg="12"
            disabled={false}
            currentValue={this.props.currentEmail}
            handleChange={this.props.handleChangeEmail}
            handleFocus={this.props.handleFocusInput}
            disablePaste={true}
          />
        </div>
      </div>
    );
  }
}

export default RadioTabButtons;
