import React, {Component} from "react";

import {connect, ConnectedProps} from "react-redux";
import {ReduxState} from "../../../common/store";
import actionTypes from "../../../actions/actionTypes";
import {isNumber} from "../../../common/helpers/functions";
import actions from "../../../actions";
import * as CONSTANTS from "../../../common/helpers/constants";

import Button from "../../Button";
import RadioTabButtons from "./RadioTabButtons/RadioTabButtons";
import CheckboxInput from "../../CheckboxInput/CheckboxInput";
import {sendGTMUserEvent} from "../../../common/helpers/gtmEvents";

class ClientPage extends Component<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);
    this.handleChangeIdCard = this.handleChangeIdCard.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);

    this.continuePage = this.continuePage.bind(this);
    this.backStartPage = this.backStartPage.bind(this);
  }

  componentDidMount() {
    sendGTMUserEvent(this.props.channel, "transaccional", "cliente");
  }

  continuePage() {
    if (!this.props.authorizeContactInfo) {
      this.props.updateStateValue(
        actionTypes.TOGGLE_CONTACT_INFO_AUTHORIZATION,
        false
      );
      return;
    }

    const payload = {
      email: this.props.emailLogin || null,
      phone: this.props.phoneLogin || null,
      identificationNumber: this.props.idCardLogin,
      identificationType: CONSTANTS.CLIENT_IDENTIFICATION_TYPE,
    };

    const { sendTransactionalContact: action, channel } = this.props;

    if (!this.props.isSendingRequest) {
      this.props.setSeindingRequest(true);
      //@ts-ignore
      grecaptcha
        .execute(CONSTANTS.REACT_APP_RECAPTCHA_TOKEN)
        .then(function (recaptchaToken: string) {
          action(payload, recaptchaToken, CONSTANTS.GUID, channel);
        });
    }
  }

  clearEmail = (): void => {
    this.props.updateInputContent('', actionTypes.UPDATE_INPUT_EMAIL_LOGIN);
  };

  clearPhone = (): void => {
    this.props.updateInputContent('', actionTypes.UPDATE_INPUT_PHONE_LOGIN);
  };

  backStartPage() {
    this.props.setLoginStep(0);
  }

  handleChangeIdCard = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;

    if (newValue.length > 10 || !isNumber(newValue[newValue.length - 1])) {
      return;
    }
    this.props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_ID_CARD_LOGIN
    );
  };

  handleChangePhone = (e: React.FormEvent<HTMLInputElement>) => {
    let newValue = e.currentTarget.value;
    if (newValue.length > 10) {
      return;
    }
    this.props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_PHONE_LOGIN
    );
  };
  handleChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value.trim();
    if (newValue.length > CONSTANTS.EMAIL_MAX_LENGHT) {
      return;
    }
    this.props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_EMAIL_LOGIN
    );
  };
  handleFocusInput = () => {
    this.props.updateStateValue(actionTypes.TOGGLE_ALERT_LOGIN, false);
  };

  render() {
    const {
      authorizeContactInfo,
      emailLoginStateError,
      phoneLoginStateError,
      phoneLoginErrorHelper,
      emailLoginErrorHelper,
      loading,
      clientContinueButtonText,
    } = this.props;

    const disableContinueButton = emailLoginStateError && phoneLoginStateError;

    const tabButtonsProps = {
      stateEmail: this.props.emailLoginState,
      currentEmail: this.props.emailLogin,
      statePhone: this.props.phoneLoginState,
      currentPhone: this.props.phoneLogin,
      phoneLoginErrorHelper,
      emailLoginErrorHelper,
      handleChangeEmail: this.handleChangeEmail,
      handleChangePhone: this.handleChangePhone,
      handleFocusInput: this.handleFocusInput,
      clearPhone: this.clearPhone,
      clearEmail: this.clearEmail,
    };

    const continueButtonProps = {
      color: "primary",
      size: "large",
      text: clientContinueButtonText,
      id: `buttonStartClient`,
      handleClick: disableContinueButton ? undefined : this.continuePage,
      testId: "continueClientPageId",
      disabled: disableContinueButton,
      loading: loading,
      tabindex: 0,
    };

    const continueButton = <Button {...continueButtonProps}></Button>;

    return (
      <div>
        <RadioTabButtons
          {...tabButtonsProps}
          label="Celular o correo registrado en el Banco"
        />

        <CheckboxInput
          id="contactInformationAuthorization"
          checked={authorizeContactInfo}
          handleClick={() =>
            this.props.updateStateValue(
              actionTypes.TOGGLE_CONTACT_INFO_AUTHORIZATION,
              !authorizeContactInfo
            )
          }
          label="Autorizo a Banco Pichincha a consultar mis datos personales y/o
          crediticios de fuentes legales de información y su respectivo tratamiento."
          tabindex={0}
          state={this.props.authorizeContactInfoState}
        />

        <div className="button-container-login">
          <div className="button-sizes" data-testid="startButtonLogin">
            {continueButton}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { login, site } = state;
  const {
    idCardLogin,
    idCardLoginState,
    idCardLoginStateError,
    completedLoginIdCard,
    phoneLogin,
    phoneLoginState,
    phoneLoginStateError,
    phoneLoginErrorHelper,
    completedPhoneLogin,
    emailLogin,
    emailLoginState,
    emailLoginStateError,
    emailLoginErrorHelper,
    completedEmailLogin,
    authorizeContactInfo,
    showAlertTransactional,
    transactionalRequestFailureMessage,
    transactionalRequestFailuresCount,
    loading,
    clientContinueButtonText,
    authorizeContactInfoState,
    isSendingRequest,
  } = login;
  const { channel, jwt } = site;
  return {
    idCardLogin,
    idCardLoginState,
    idCardLoginStateError,
    completedLoginIdCard,
    phoneLogin,
    phoneLoginState,
    phoneLoginStateError,
    phoneLoginErrorHelper,
    completedPhoneLogin,
    emailLogin,
    emailLoginState,
    emailLoginStateError,
    emailLoginErrorHelper,
    completedEmailLogin,
    authorizeContactInfo,
    channel,
    showAlertTransactional,
    transactionalRequestFailureMessage,
    transactionalRequestFailuresCount,
    loading,
    clientContinueButtonText,
    jwt,
    authorizeContactInfoState,
    isSendingRequest,
  };
};

const {
  updateInputContent,
  setLoginStep,
  toggleCompleteIdCard,
  updateStateValue,
  sendTransactionalContact,
  requestOtpResend,
  setSeindingRequest,
} = actions;

const connector = connect(mapStateToProps, {
  updateInputContent,
  setLoginStep,
  toggleCompleteIdCard,
  updateStateValue,
  sendTransactionalContact,
  requestOtpResend,
  setSeindingRequest,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ClientPage);
